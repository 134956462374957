<template>
  <div class="validation-errors" v-if="!lodash.isEmpty(serverValidation) && serverValidation[field]">
    <p class="error">
      {{ ERROR_MESSAGES[serverValidation[field].code] }}
    </p>
  </div>
</template>
<script>
import lodash from 'lodash'
import { defineComponent } from 'vue'

const ERROR_MESSAGES = {
  101: 'A mező kitöltése kötelező',
  102: 'A mező tartalma nem elég hosszú',
  103: 'A mező tartalma túl hosszú',
  104: 'Az email formátuma nem megfelelő',
  105: 'A mező tartalma nem szöveg',
  106: 'Már van ilyen elem az adatbázisban',
  107: 'Formátum nem megfelelő',
  108: 'A mezők tartalmai nem azonosak',
  109: 'Érvénytelen mező',
  110: 'A mező csak betűt és számot tartalmazhat, szóközt, specieális karaktert nem',
  111: 'A mező tartalma nem fájl',
  112: 'Nem támogatott fájl típus',
  113: 'Null értéket is felvehet',
  114: 'A mező nem szám típusú',
  115: 'A mező nem boolean típusú',
  116: 'Ez az e-mail cím már foglalt. Lépj be, vagy kérj jelszóemlékeztetőt!',
  117: 'Hibás e-mail cím, ellenőrizd kérlek',
  118: 'A kép méretei nem megfelelőek!',
  119: 'A képfájl mérete túl nagy!',
}

export default defineComponent({
  name: 'ServerValidation',
  props: {
    field: {
      type: String,
      required: true,
    },
    serverValidation: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      ERROR_MESSAGES,
      lodash,
    }
  },
})
</script>

<style lang="scss" scoped>
.validation-errors {
  .error {
    font-weight: bold;
    color: #e45043;
    margin: 5px 0 5px 5px;
  }
}
</style>
