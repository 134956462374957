import lodash from 'lodash'
import { ref } from 'vue'

export default function useFormControl() {
  const serverValidation = ref({})

  const hasServerValidationError = field => !lodash.isEmpty(serverValidation.value) && serverValidation.value[field]

  return {
    serverValidation,
    hasServerValidationError,
  }
}
