<template>
  <div class="validation-errors" v-if="field.$error">
    <p class="error" v-for="$error in field.$errors" :key="$error.$property">
      {{ getValidationMessage($error.$validator) }}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

const ERROR_MESSAGES = {
  REQUIRED: 'A mező kitöltése kötelező',
  EMAIL: 'Az email mező formátuma nem megfelelő',
  TEL: 'A telefonszám formátuma nem megfelelő',
  MINLENGTH: 'A mező tartalma nem elég hosszú',
  MAXLENGTH: 'A mező tartalma túl hosszú',
  PRIVACY: 'El kell fogadnod az Adatkezelési elveinket.',
  ACCEPTANCE: 'El kell fogadnod a Felhasználási feltételeket.',
  DEFAULT: 'A mező tartalma nem megfelelő',
  DATE: 'A dátum nem lehet a jövőben',
  PASSWORD_MISMATCH: 'A jelszavak nem egyeznek',
  ONLY_HEALTHY: 'Teljesen egészséges kedvenchez nem tudsz további betegségeket hozzáadni.',
}

export default defineComponent({
  name: 'ErrorMessage',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  setup() {
    function getValidationMessage(validator) {
      const errorMessage = ERROR_MESSAGES[validator.toUpperCase()]

      if (!errorMessage) {
        return ERROR_MESSAGES.DEFAULT
      }

      return errorMessage
    }

    return {
      getValidationMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
.validation-errors {
  .error {
    font-weight: bold;
    color: #e45043;
    margin: 5px 0 5px 5px;
  }
}
</style>
